/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'prize': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M15 1.999h-2a2 2 0 00-2-2H5a2 2 0 00-2 2H1a1 1 0 00-1 1v3c0 .965.749 2.71 3.4 2.952A5.017 5.017 0 007 11.899v2.1H5a1 1 0 000 2h6a1 1 0 000-2H9v-2.1a5.017 5.017 0 003.6-2.947C15.249 8.71 16 6.965 16 6V3a1 1 0 00-1-1.001zM2 5.97V3.999h1v2.882a1.125 1.125 0 01-1-.911zm6 4.029a3 3 0 01-3-3v-5h6v5a3 3 0 01-3 3zm5-3.118V3.999h1V5.97a1.125 1.125 0 01-1 .911z" _fill="#898B9B"/>'
  }
})
